<template>
    <div class="matrix-limos pt-5 pb-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-12 text-center">
                    <h3>Welcome To Matrix Limos</h3>
                </div>
            </div>
            <div class="row gy-md-5">
                <div class="col-lg-4" v-for="(matrixlimos, i) in matrixlimoses" v-bind:key="i">
                    <div class="blurb">
                        <div class="left-side">
                            <div class="icon-wrapp rounded-circle" v-html="matrixlimos.icon">
                               
                            </div>
                        </div>
                        <div class="right-side">
                            <div class="content-wrapp">
                                <h4>{{ matrixlimos.name }}</h4>
                                <p>{{ matrixlimos.discription }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        name: 'MatrixLimos',
        data: function(){
        return {
            matrixlimoses:[
                {
                    name: 'Online Reservation', 
                    discription: 'Reserve through this website at least 3 hours in advance of the service pick-up time.',
                    icon: '<i class="fas fa-car"></i>'
                },
                {
                    name: 'Free Quote', 
                    discription: 'No hidden fees. The service fare is disclosed before the beginning of the pre-arranged ride', 
                   icon: '<i class="fas fa-pencil-alt"></i>'
                },
                {
                    name: 'Airport Curbside Pickup', 
                    discription: 'When you’re curbside with your luggage, just call or text your driver with your airport zone number and anything distinctive about your appearance (perhaps the color of your jacket).',
                    icon: '<i class="fas fa-user"></i>'
                },
                {
                    name: 'Safe & Secure Website', 
                    discription: 'The padlock icon on your browser simply represents that traffic to and from this website is encrypted.',
                    icon: '<i class="fas fa-lock"></i>'
                },
                {
                    name: 'Instant Confirmation', 
                    discription: 'Reserve your Matrix Limos online and receive an instant confirmation by e-mail.',
                    icon: '<i class="fas fa-check"></i>'
                },
                {
                    name: 'Flight Tracking System', 
                    discription: 'This technology ensures that chauffeurs will be on time for your airports pick up all time.',
                    icon: '<i class="fas fa-plane"></i>'
                },
                {
                    name: 'Real-Time Notifications', 
                    discription: 'Driver and vehicle details, on location, on board and trip complete notifications.',
                    icon: '<i class="fas fa-comment-dots"></i>'
                },
                {
                    name: 'Mobile App', 
                    discription: 'Easily access all of your travel management tools from the convenience of your phone.',
                    icon: '<i class="fas fa-tablet-alt"></i>'
                },
                {
                    name: 'Safe and Secure Travel', 
                    discription: 'Travel confidently in sanitized premium vehicles driven by professionals.',
                    icon: '<i class="fas fa-handshake"></i>'
                },

            ]
        }
       
    },
    }
</script>
<style scoped lang="scss">
.matrix-limos{
    h3{
        color: #00bfff;
        font-style: italic;
    }
   .blurb{
       display: flex;
       .left-side{
           margin-right: 35px;
           .icon-wrapp{
               width: 60px;
               height: 60px;
               background: #6ADAFF4F;
               display: flex;
                align-items: center;
                justify-content: center;
                color: #1f86e2;
                font-size: 30px;
           }
       }
       .right-side{
           .content-wrapp{
               h4{
                    font-size: 18px;
                    font-weight: 600;
               }
               p{
                   color: #7a7a7a;
               }
           }
       }
   }
}
</style>